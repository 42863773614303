import { fetchWrapper } from '@/helpers/fetch-wrapper'

const baseUrl = `${import.meta.env.VITE_API_URL}`

const authService = {
  _verifyLogin: () => {
    return fetchWrapper.get(`${baseUrl}/verify-login`, null, null)
  },
  _verifyAndUpdateToken: () => {
    return fetchWrapper.get(`${baseUrl}/verify-and-update-token`, null, null)
  },
  getSelfData: () => {
    return fetchWrapper.get(`${baseUrl}/get-own-data`, null, null)
  },
  sendRequestByCIBA: (cpf) => {
    return fetchWrapper.post(`${baseUrl}/ciba-req`, { cpf }, null)
  },
  verifyAuthByCIBA: (platform) => {
    return fetchWrapper.get(`${baseUrl}/ciba-auth?platform=${platform}`, null, null)
  },
}

export default authService
