<script setup>
  import { RouterView } from 'vue-router'
  import { onMounted, watch } from 'vue'
  import { useAuthStore } from '@/stores/auth'
  import helpers from '@/helpers'

  const auth = useAuthStore()

  onMounted(() => {
    carregarHeaderFooter()
  })

  const suporteFooter = {
    '/rc': {
      telefone: '(11) 5555-9372',
      email: 'suporte.cliente@registrocivil.org.br',
    },
    '/redeconsular': {
      telefone: '(11) 5555-9252',
      email: 'suporte.crc@registrocivil.org.br',
    },
  }

  const carregarHeaderFooter = async () => {
    const oldScript = document.querySelector('script[data-header-footer]')

    if (oldScript) {
      oldScript.remove()
    }

    const script = document.createElement('script')
    script.setAttribute('type', 'module')
    script.setAttribute('data-header-footer', 'true')

    const data = {
      usuario: {
        nome: auth.user?.name || '',
        cpf: helpers.formatarCPF(auth.user?.cpf) || '',
        foto: auth.user?.foto_de_perfil_url || '',
        grupos: auth.user?.groups || [],
      },
      urlLogout: auth.urlLogout(),
      token: auth.getToken(),
      cartorio:
        auth.user?.notaries && auth.user?.notaries?.length ? auth.user?.notaries[0]?.nome : '',
      exibirPerguntasFrequentes: true,
      exibirFaleConoscoPeloChat: true,
      exibirWhatsApp: true,
      ...(['/rc', '/redeconsular'].includes(window.location.pathname)
        ? {
            urlLogin: `${import.meta.env.VITE_BASE_URL}${window.location.pathname}`,
            suporte: suporteFooter[window.location.pathname],
            exibirAcessoRemoto: true,
          }
        : {
            urlLogin: auth.urlLoginScreen(),
          }),
    }

    script.innerHTML = `
      import { instalarHeaderFooter } from '${
        import.meta.env.VITE_URL_BASE_HEADER_FOOTER
      }/header-footer.min.js';
      instalarHeaderFooter(${JSON.stringify(data)});
  `

    document.body.appendChild(script)
  }

  watch(
    () => auth.user,
    (newValue) => {
      if (newValue) {
        carregarHeaderFooter()
      }
    }
  )
</script>

<template>
  <div id="appContainer">
    <div id="header" />
    <div id="body">
      <div>
        <RouterView />
      </div>
    </div>
    <div id="footer" />
  </div>
</template>

<style scoped lang="scss">
  #appContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  #header,
  #body,
  #body > div,
  #footer {
    flex: 1;
  }

  #header,
  #footer {
    display: contents;
  }

  #body {
    display: flex;

    > div {
      min-height: 640px;
      display: flex;
      justify-content: center;
      padding: 64px 16px 32px;
      width: 100%;
    }
  }
</style>
