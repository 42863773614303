const Helper = {
  deepClone: (obj) => {
    return JSON.parse(JSON.stringify(obj))
  },
  // Retorna alterações no novo obj
  changedData: (newObj, oldObj) => {
    const keys = Object.keys(newObj)
    const result = {}

    for (const key of keys) {
      if (newObj[key] !== oldObj[key]) {
        result[key] = newObj[key]
      }
    }

    return result
  },
  maskForm: (data, mask) => {
    const dataSplit = data.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '').split('')
    dataSplit.forEach((character) => {
      mask = mask.replace('#', character)
    })
    return mask
  },
  formatarCPF(cpf) {
    if (!cpf) return
    const cpfLimpo = cpf.replace(/\D/g, '')
    if (cpfLimpo.length === 11) {
      return (
        cpfLimpo.substring(0, 3) +
        '.' +
        cpfLimpo.substring(3, 6) +
        '.' +
        cpfLimpo.substring(6, 9) +
        '-' +
        cpfLimpo.substring(9)
      )
    }
  },
  validEmail(email) {
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return regexEmail.test(email)
  },
}

export default Helper
