import { useToast, TYPE } from 'vue-toastification'
import router from '@/router'

const toast = useToast()

export const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
}

function request(method) {
  return async (url, body, withFiles) => {
    const requestOptions = {
      method,
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    }
    if (body) {
      if (!withFiles) requestOptions.headers['Content-Type'] = 'application/json'
      requestOptions.body = !withFiles ? JSON.stringify(body) : body
    }

    return fetch(url, requestOptions)
      .then(handleResponse)
      .catch((err) => {
        if (import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'production') console.log(err)
      })
  }
}

function handleResponse(response) {
  if (response.status === 304) return toast('Nada modificado.', { type: TYPE['WARNING'] })
  return response.text().then(async (text) => {
    const data = text && JSON.parse(text.toString())

    if (!response.ok) {
      let messages = null
      if (data?.messages) messages = Object.values(data.messages).join('\n')

      let msg = messages || data?.message || response.statusText

      if (msg === 'Unauthorized') msg = 'Acesso Negado'

      if (data.toast || data.message || data.messages) {
        toast(msg.toString(), { type: TYPE[data.toast] || 'error' })

        if (data.redirect) await router.push(data.redirect)
      }
      return data
    } else {
      if (data.toast && data.message) {
        toast(data.message, { type: TYPE[data.toast] || 'info' })
      }
      if (data.redirect) await router.push(data.redirect)
    }

    return data
  })
}
