import { defineStore } from 'pinia'
import authService from '@/services/auth'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    token: getToken(),
    user: null,
    refreshTokenTimeout: null,
  }),
  actions: {
    async verifyLogin() {
      await authService._verifyLogin()
      this.refreshToken()
    },
    urlLoginScreen() {
      return `${import.meta.env.VITE_BASE_URL}/login`
    },
    redirectLogin(platform) {
      window.location.href = `${import.meta.env.VITE_API_URL}/redirectPlatform?platform=${platform}`
    },
    urlLogin(platform = 'home', flow = 'password') {
      const params = new URLSearchParams({
        redirectUrl: `${import.meta.env.VITE_BASE_URL}`,
        flow,
      })

      return `${import.meta.env.VITE_API_URL}/login?platform=${platform}&${params.toString()}`
    },
    urlLogout() {
      const params = new URLSearchParams({
        redirectUrl: location.protocol + '//' + location.host,
      })

      return `${import.meta.env.VITE_API_URL}/logout?${params.toString()}`
    },
    logoutCallback() {
      this.token = null
      this.usuario = null
      localStorage.removeItem('cartorio')
    },
    async verifyAndUpdateToken() {
      try {
        await authService._verifyAndUpdateToken()
        this.refreshToken()
      } catch (e) {
        await this.logoutCallback()
      }
    },
    refreshToken() {
      this.token = getCookieToken() ? jwt_decode(getToken()) : null
      clearTimeout(this.refreshTokenTimeout)
      if (this.token) {
        const executeAt = this.token.refreshAt - Date.now()

        console.log('.', new Date(this.token.expiresAt))
        console.log('..', new Date(this.token.refreshAt))

        this.refreshTokenTimeout = setTimeout(async () => {
          console.log('...', new Date())
          if (this.token) await this.verifyAndUpdateToken()
        }, executeAt)
      }
    },
    getToken() {
      return getToken()
    },
  },
})

const getCookieToken = () => Cookies.get('application-token')

const getToken = () => {
  try {
    const token = getCookieToken()
    return token && JSON.parse(atob(token)).message
  } catch (e) {
    return null
  }
}
